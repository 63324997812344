/**
 * The purpose of this is to be an easily mockable way of getting the current time.
 *
 * The service should be instantiated in the window object. See gatsby-browser.tsx.
 *
 * The time service should be used in all business-logics that need to
 * know the current time so that we might want to mock it.
 */

export interface ITimeService {
  now: () => Date;
}

export class TimeService implements ITimeService {
  now() {
    return new Date();
  }
}

export function getNow() {
  if (typeof window !== 'undefined' && window.timeService) {
    return window.timeService.now();
  } else {
    console.warn('window.timeService not set. Using system time.');
    return new Date();
  }
}
