exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-event-seating-chart-maker-software-tsx": () => import("./../../../src/pages/event-seating-chart-maker-software.tsx" /* webpackChunkName: "component---src-pages-event-seating-chart-maker-software-tsx" */),
  "component---src-pages-ewon-tsx": () => import("./../../../src/pages/ewon.tsx" /* webpackChunkName: "component---src-pages-ewon-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-tsx": () => import("./../../../src/pages/info.tsx" /* webpackChunkName: "component---src-pages-info-tsx" */),
  "component---src-pages-licenses-tsx": () => import("./../../../src/pages/licenses.tsx" /* webpackChunkName: "component---src-pages-licenses-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-wedding-seating-chart-maker-software-tsx": () => import("./../../../src/pages/wedding-seating-chart-maker-software.tsx" /* webpackChunkName: "component---src-pages-wedding-seating-chart-maker-software-tsx" */),
  "component---src-pages-wheel-of-names-tsx": () => import("./../../../src/pages/wheel-of-names.tsx" /* webpackChunkName: "component---src-pages-wheel-of-names-tsx" */)
}

