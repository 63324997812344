import '@fontsource-variable/open-sans';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { GatsbyBrowser } from 'gatsby';
import { TimeService } from './src/TimeService';
import './src/styles/global.css';

// Assign an instance of TimeService to a global variable.
// Note: if we remove this, fix also the window type declaration in timeService.d.ts.
window.timeService = new TimeService();

let app: FirebaseApp | undefined;

function isStagingEnv() {
  // Development env, that is build (no npm start dev server)
  // TODO: Maybe these could be better... (GATSBY_ACTIVE_ENV is our own var set in package.json scripts)
  return (
    process.env.GATSBY_ACTIVE_ENV === 'development' &&
    process.env.NODE_ENV === 'production'
  );
}

function isProdEnv() {
  return (
    process.env.GATSBY_ACTIVE_ENV === undefined &&
    process.env.NODE_ENV === 'production'
  );
}

function isDoNotTrack() {
  let doNotTrack: boolean | undefined;
  if (
    (window as any).doNotTrack ||
    navigator.doNotTrack ||
    (navigator as any).msDoNotTrack
  ) {
    if (
      (window as any).doNotTrack === '1' ||
      navigator.doNotTrack === 'yes' ||
      navigator.doNotTrack === '1' ||
      (navigator as any).msDoNotTrack === '1'
    ) {
      doNotTrack = true;
    } else {
      doNotTrack = false;
    }
  } else {
    doNotTrack = undefined;
  }
  return doNotTrack === undefined ? false : doNotTrack;
}

function initAnalyticsOnEvent(event: any) {
  event.currentTarget.removeEventListener(event.type, initAnalyticsOnEvent); // remove the event listener that got triggered
  initAnalytics();
}

function initAnalytics() {
  if ((window as any).analyticsInitDone) {
    return false;
  }
  (window as any).analyticsInitDone = true; // flag to ensure script does not get added to DOM more than once.

  if (isProdEnv() || isStagingEnv()) {
    const analytics = getAnalytics(app);
    if (isDoNotTrack()) {
      // GA 4
      // app.automaticDataCollectionEnabled = false;
      logEvent(analytics, 'do_not_track');
    }
  }

  /**
   * Hotjar is initialized in the AppLayout component. This allows us loading Hotjar only when the
   * user is logged in, which could help with Google/Lighthouse performance on the /app/-route.
   */
  const useHotjar = isProdEnv() || isStagingEnv();
  if (useHotjar) {
    var hotjarInitScriptEl = document.createElement('script');
    hotjarInitScriptEl.type = 'text/javascript';
    hotjarInitScriptEl.async = true;
    hotjarInitScriptEl.defer = true;
    hotjarInitScriptEl.innerHTML = `
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3098645,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `;
    document.head.appendChild(hotjarInitScriptEl);
  }
}

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  if (process.env.GATSBY_FIREBASE_API_KEY) {
    const firebaseConfig = {
      apiKey: process.env.GATSBY_FIREBASE_API_KEY,
      authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.GATSBY_FIREBASE_DB_URL,
      projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
      storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.GATSBY_FIREBASE_APP_ID,
      measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
    };

    // Initialize Firebase
    app = initializeApp(firebaseConfig);

    // Auth and firestore initialized in App-component. (Features used only in app.)

    // https://github.com/gatsbyjs/gatsby/issues/24332#issuecomment-752373784
    document.onreadystatechange = function () {
      if (document.readyState !== 'loading') {
        setTimeout(initAnalytics, 3500);
      }
    };

    document.addEventListener('scroll', initAnalyticsOnEvent);
    document.addEventListener('mousemove', initAnalyticsOnEvent);
    document.addEventListener('touchstart', initAnalyticsOnEvent);
  }
};
